import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { destroyLoggedInUser } from "../store/loggedInUser.slice";
import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { useHistory } from "react-router-dom";
import avatar from "../photos/user2.png";

const Header = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { name, url } = useSelector((state) => state.loggedInUser);

  const logout = async () => {
    dispatch(destroyLoggedInUser());
    history.push("/");
  };

  return (
    <Disclosure as="nav" className="bg-gray-800 sticky top-0">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden"></div>
              <div className="flex-1 flex items-center  sm:items-stretch sm:justify-start">
                <div className="flex-shrink-0 flex items-center ml-2">
                  <img
                    className="block lg:hidden h-8 w-auto"
                    src={window.location.origin + "/logo512.png"}
                    alt="Workflow"
                  />
                  <img
                    className="hidden lg:block h-8 w-auto"
                    src={window.location.origin + "/logo512.png"}
                    alt="Workflow"
                  />
                </div>
                <div className="hidden sm:block sm:ml-6">
                  <div className="flex space-x-4"></div>
                </div>
              </div>

              {name && (
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  {/* Profile dropdown */}
                  <Menu as="div" className="ml-3 relative">
                    {({ open }) => (
                      <>
                        <div>
                          <Menu.Button className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                            <span className="sr-only">Open user menu</span>
                            <img
                              className="h-10 w-10 rounded-full"
                              src={url ? url : avatar}
                              alt={url ? url : avatar}
                            />
                          </Menu.Button>
                        </div>
                        <Transition
                          show={open}
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items
                            static
                            className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                          >
                            <Menu.Item>
                              {({ active }) => (
                                <span
                                  className={
                                    "block px-4 py-2 text-sm text-gray-900 border-b-2"
                                  }
                                >
                                  Welcome, <b>{name}</b>!
                                </span>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="!#"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    logout();
                                  }}
                                  className={
                                    "block px-4 py-2 text-sm text-blue-600 no-underline font-bold"
                                  }
                                >
                                  Sign out
                                </a>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </>
                    )}
                  </Menu>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </Disclosure>
  );
};

export default Header;
