import { authedAxiosInstance } from "./api.service";

const PartService = {
  get_wholesale_inventory: async () => {
    const response = await authedAxiosInstance.get(
      "/parts?status=wholesale",
      {}
    );
    return response.data.data;
  },
  get: async (id) => {
    const response = await authedAxiosInstance.get(`/parts/${id}`, {});
    return response.data.data;
  },
};

export default PartService;
