import React from "react";
import { Row } from "react-bootstrap";

const Footer = () => {
  return (
    <Row>
      <p className="mt-4 text-center">
        PY Catalog - All rights reserved. {new Date().getFullYear()}
      </p>
    </Row>
  );
};

export default Footer;
