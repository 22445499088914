import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import AuthService from "../api/auth.service";
import Layout from "../components/Layout";
import { Row } from "react-bootstrap";
import { setLoggedInUser } from "../store/loggedInUser.slice";

const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleSubmit = async (event) => {
    setErrors(null);
    event.preventDefault();

    try {
      setIsLoading(true);
      const loggedInUser = await AuthService.login({
        email: event.target.email.value,
        password: event.target.password.value,
      });
      setIsSuccess(true);
      setTimeout(() => {
        dispatch(setLoggedInUser(loggedInUser));
        history.push("/inventories");
      }, 1000);
    } catch (error) {
      setIsLoading(false);
      setErrors([
        error.response.data === undefined
          ? error.response
          : error.response.data.message,
      ]);
    }
  };

  return (
    <Layout>
      <Row className="bg-gray-500">
        <div className="flex h-screen justify-center items-center">
          <div className="w-full max-w-screen-md">
            {!isLoading && (
              <form
                className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
                onSubmit={handleSubmit}
              >
                <div className="mb-4">
                  <img
                    className="text-right"
                    src={window.location.href + "/logo192.png"}
                    alt="logo"
                    style={{ maxHeight: "35px", width: "auto" }}
                  />
                </div>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="email"
                  >
                    Username
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="email"
                    type="email"
                    name="email"
                    placeholder="Username"
                  />
                </div>
                <div className="mb-6">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="password"
                  >
                    Password
                  </label>
                  <input
                    className="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    type="password"
                    name="password"
                    placeholder="******************"
                  />
                </div>
                <div className="flex text-center justify-between">
                  <button
                    className="w-full bg-blue-400 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="submit"
                  >
                    Login
                  </button>
                </div>
                {errors && (
                  <Row>
                    <div className="bg-white-900 text-center py-4 lg:px-4">
                      <div
                        className="p-2 bg-red-400  items-center text-red-100 leading-none rounded-full flex lg:inline-flex"
                        role="alert"
                      >
                        <span className="flex rounded-full bg-red-600 uppercase px-2 py-1 text-xs font-bold mr-3">
                          Error
                        </span>
                        <span className="font-semibold mr-2 text-left flex-auto">
                          {errors}
                        </span>
                      </div>
                    </div>
                  </Row>
                )}
              </form>
            )}

            {isLoading && (
              <div
                className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4"
                role="alert"
              >
                {!isSuccess && (
                  <div>
                    <p className="font-bold text-lg">Please wait...</p>
                    <p className="text-lg">
                      We are authenticating your information.
                    </p>
                  </div>
                )}
                {isSuccess && (
                  <div>
                    <p className="font-bold text-lg">Authenticaded...</p>
                    <p className="text-lg">Welcome to the system!</p>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </Row>
    </Layout>
  );
};

export default Login;
