import React from "react";
import Layout from "../components/Layout";
import { useSelector } from "react-redux";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Inventories from "../pages/Inventories";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

const Dashboard = () => {
  const { name } = useSelector((state) => state.loggedInUser);

  return (
    <div>
      {name && (
        <div>
          <Header />
          <Layout>
            <Inventories />
            <Footer />
          </Layout>
        </div>
      )}

      {!name && (
        <div
          className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4"
          role="alert"
        >
          <p className="font-bold text-xl lg:text-2xl">
            <FontAwesomeIcon icon={faTimesCircle} /> Ops... something doesn't
            seen right.
          </p>
          <p className="font-bold text-md lg:text-xl text-red-600">
            You need to be authenticated to access this content. Please
            authenticate{" "}
            <a href={window.location.origin} className="text-blue-600">
              here
            </a>
            .
          </p>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
