import React, { useState, useEffect, Fragment } from "react";
import PartService from "../api/part.service";
import { Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAmazon, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import {
  faTimesCircle,
  faLock,
  faSearch,
  faMailBulk,
} from "@fortawesome/free-solid-svg-icons";
import Cryptr from "cryptr";
import { Dialog, Transition } from "@headlessui/react";

const Inventories = () => {
  const cryptr = new Cryptr("myTotalySecretKey");

  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [price, setPrice] = useState(false);
  const [newPrice, setNewPrice] = useState(null);
  const [amount, setAmount] = useState(null);
  const [selectedPart, setSelectedPart] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    PartService.get_wholesale_inventory()
      .then(async (inventories) => {
        setData(
          inventories.sort((a, b) =>
            a.InventoryCount > b.InventoryCount ? -1 : 1
          )
        );
      })
      .catch(async (error) => {
        console.log(error);
        setError(
          error.message === "Request failed with status code 401"
            ? "Authentication is required for this content."
            : error.message
        );
        setTimeout(() => {
          setError("Redirecting you to the login page...");
          setTimeout(() => {
            window.location.replace(window.location.origin);
          }, 2000);
        }, 5000);
      });
  }, []);

  function sendOffer(event, price) {
    setOpen(false);

    event.preventDefault();

    setLoading(true);

    const whatsapp_url = "https://wa.me/?text=";

    setTimeout(() => {
      if (!price) {
        const url =
          whatsapp_url +
          encodeURIComponent(
            `Hello, check my offer for *${selectedPart.Description}* on this link: ${window.location.origin}/offer/${selectedPart._id}`
          );
        window.open(url, "_blank", "noopener,noreferrer");
      } else {
        const newp = cryptr.encrypt(price);
        const url =
          whatsapp_url +
          encodeURIComponent(
            `Hello, check my offer for *${selectedPart.Description}* on this link: ${window.location.origin}/offer/${selectedPart._id}&pr=${newp}`
          );
        window.open(url, "_blank", "noopener,noreferrer");
      }

      setLoading(false);
      setPrice(false);
      setNewPrice(null);
      setAmount(null);
      setSelectedPart(null);
    }, 1 * 1000);
  }

  function setPart(part) {
    setPrice(true);
    setSelectedPart(part);
  }

  function filterList(e) {
    e.preventDefault();

    try {
      const word = e.target.form[0].value;

      PartService.get_wholesale_inventory()
        .then(async (inventories) => {
          inventories = inventories.filter((inv) => {
            return (
              inv.PartNumber.toLowerCase().includes(word.toLowerCase()) ||
              inv.Description.toLowerCase().includes(word.toLowerCase())
            );
          });

          setData(
            inventories.sort((a, b) =>
              a.InventoryCount > b.InventoryCount ? -1 : 1
            )
          );
        })
        .catch(async (error) => {
          console.log(error);
          setError(
            error.message === "Request failed with status code 401"
              ? "Authentication is required for this content."
              : error.message
          );
          setTimeout(() => {
            setError("Redirecting you to the login page...");
            setTimeout(() => {
              window.location.replace(window.location.origin);
            }, 2000);
          }, 5000);
        });
    } catch (e) {
      alert(e);
    }
  }

  function sendEmail(part, amount) {
    setOpen(false);

    const sub = encodeURIComponent(`Check my offer for ${part.PartNumber}`);

    const body = encodeURIComponent(
      amount
        ? `Hello, check my offer for ${
            selectedPart.Description
          } on this link: ${window.location.origin}/offer/${
            selectedPart._id
          }&pr=${cryptr.encrypt(amount)}`
        : `Hello, check my offer for ${part.Description} on this link: ${window.location.origin}/offer/${part._id}`
    );

    const url = "mailto:yourcontact@email.com?subject=" + sub + "&body=" + body;

    window.open(url, "_blank", "noopener,noreferrer");

    setLoading(false);
    setPrice(false);
    setNewPrice(null);
    setAmount(null);
    setSelectedPart(null);
  }

  return (
    <Row>
      {selectedPart && (
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed z-10 inset-0 overflow-y-auto"
            open={open}
            onClose={setOpen}
          >
            <div className="justify-center min-h-screen text-center items-end sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                  <div className="bg-white px-4 pt-5 sm:p-6">
                    <div className="sm:flex sm:items-start">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        Share via...
                      </Dialog.Title>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-2 py-2 sm:px-6 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="w-full inline rounded-md border border-transparent px-2 py-2 shadow-sm bg-indigo-600 text-white hover:bg-indigo-700"
                      onClick={(e) => sendEmail(selectedPart, amount)}
                    >
                      <FontAwesomeIcon className="text-lg" icon={faMailBulk} />
                    </button>
                    <button
                      type="button"
                      className="w-full inline rounded-md border border-transparent px-2 py-2 shadow-sm bg-green-500 text-white hover:bg-green-700"
                      onClick={(e) => sendOffer(e, amount)}
                    >
                      <FontAwesomeIcon className="text-lg" icon={faWhatsapp} />
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      )}

      {!data && !error && !price && (
        <div
          className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4"
          role="alert"
        >
          <p className="font-bold text-md lg:text-xl">Loading</p>
          <p className="text-md lg:text-xl">Please wait...</p>
        </div>
      )}
      {!data && error && (
        <div
          className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4"
          role="alert"
        >
          <p className="font-bold text-xl lg:text-2xl">
            <FontAwesomeIcon icon={faTimesCircle} /> Ops... something doesn't
            seen right.
          </p>
          <p className="font-bold text-md lg:text-xl text-red-600">{error}</p>
        </div>
      )}
      {data && !price && data.length === 0 && (
        <div
          className="bg-gray-600 sticky top-0"
          style={{ textAlign: "center" }}
        >
          <div className="mx-auto py-3 px-3 sm:px-8 lg:px-8 inline-block">
            <div className="flex items-center justify-between flex-wrap">
              <div className="mt-2 w-full sm:order-2 sm:mt-0 sm:w-auto">
                <form>
                  <input
                    id="partnumber"
                    type="text"
                    className="rounded text-lg text-dark-500 py-2 px-2 inline-block"
                    placeholder="Type to filter the list..."
                  />

                  <button
                    type="button"
                    onClick={(e) => filterList(e)}
                    className="py-2 px-2 p-2 rounded-md hover:bg-indigo-800 bg-indigo-600 text-lg focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2 text-white self-center"
                  >
                    Search
                    <FontAwesomeIcon
                      icon={faSearch}
                      className="ml-2 text-white"
                      aria-hidden="true"
                    />
                  </button>
                </form>
              </div>
            </div>
            <div
              className="mt-4 p-2 bg-red-400  items-center text-red-100 leading-none rounded-full flex lg:inline-flex"
              role="alert"
            >
              <span className="flex rounded-full bg-red-600 uppercase px-2 py-1 text-xs font-bold mr-3">
                No data found
              </span>
              <span className="font-semibold mr-2 text-left flex-auto">
                Please refine your search.
              </span>
            </div>
          </div>
        </div>
      )}
      {data && !price && data.length > 0 && (
        <div
          className="bg-gray-600 static top-0"
          style={{ textAlign: "center" }}
        >
          <div className="mx-auto py-3 px-3 sm:px-8 lg:px-8 inline-block">
            <div className="flex items-center justify-between flex-wrap">
              <div className="mt-2 w-full sm:order-2 sm:mt-0 sm:w-auto">
                <form>
                  <input
                    id="partnumber"
                    type="text"
                    className="rounded text-lg text-dark-500 py-2 px-2 inline-block"
                    placeholder="Type to filter the list..."
                  />

                  <button
                    type="button"
                    onClick={(e) => filterList(e)}
                    className="py-2 px-2 p-2 rounded-md hover:bg-indigo-800 bg-indigo-600 text-lg focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2 text-white self-center"
                  >
                    Search
                    <FontAwesomeIcon
                      icon={faSearch}
                      className="ml-2 text-white"
                      aria-hidden="true"
                    />
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      {data &&
        !price &&
        data.length > 0 &&
        data.map((part, index) => {
          return (
            <div
              key={index}
              className="overflow-hidden shadow-lg text-center w-max p-10 lg:p-40 mb-4 h-screen bg-gray-100"
            >
              <img
                className="max-w-sm mx-auto h-40 md:h-64 lg:h-96"
                src={part.URL}
                alt={part.URL}
              />
              <div className="mt-2 px-6 py-4">
                <div className="mb-2">
                  <span className="inline-block bg-blue-500 rounded-full px-3 py-1 font-bold text-white mr-2 mb-2 text-lg lg:text-3xl">
                    {part.PartNumber}
                  </span>
                </div>
                <p className="text-gray-700 text-sm lg:text-xl mt-2 mb-2">
                  {part.Description}
                </p>
                {part.Model && (
                  <p className="text-gray-700 text-sm lg:text-xl mt-2 mb-2">
                    <b>Model:</b> {part.Model}
                  </p>
                )}
                <p className="text-gray-700 text-sm lg:text-xl mt-2 mb-2">
                  <b>Brand:</b> {part.Brand}
                </p>
                <p className="text-gray-700 text-sm lg:text-xl mt-2 mb-2">
                  <b>Dimensions:</b> {part.Dimensions}
                </p>
                <p className="text-gray-700 text-sm lg:text-xl mt-2 mb-2">
                  <b>Weight:</b> {part.Weight}
                </p>
                <p className="text-gray-700 text-sm lg:text-xl mt-2 mb-2">
                  <b>Unit Price:</b>{" "}
                  <span className="text-red-600 font-bold">{part.Price}</span>
                </p>
                <p className="text-gray-700 text-sm lg:text-xl mt-2 mb-2">
                  <b>Quantity Available:</b>{" "}
                  <span className="text-green-600 font-bold">
                    {part.InventoryCount}
                  </span>
                </p>

                <div className="mt-4">
                  {part.ASIN && (
                    <a
                      href={`https://amazon.com/s?k=${part.ASIN}`}
                      target={"_blank"}
                      rel={"noreferrer"}
                      className="bg-yellow-300 hover:bg-yellow-400 text-dark font-bold py-2 px-3 lg:py-3 lg:px-6 rounded text-sm lg:text-xl no-underline inline-block"
                    >
                      Check Listing on &nbsp;
                      <FontAwesomeIcon icon={faAmazon} />
                    </a>
                  )}

                  <button
                    onClick={(e) => setPart(part)}
                    className="bg-teal-500 hover:bg-teal-600 text-dark font-bold py-2 px-3 lg:py-3 lg:px-6 rounded text-sm lg:text-xl no-underline ml-2 lg:ml-4 text-white inline-block sm:mt-2"
                  >
                    Share Offer
                  </button>
                </div>
              </div>
              <div className="px-6 pt-2 pb-2">
                {part.UPC && (
                  <span className="inline-block bg-purple-200 rounded-full px-3 py-1 text-sm lg:text-base font-semibold text-purple-700 mr-2 mb-2">
                    UPC: {part.UPC}
                  </span>
                )}
                {part.Condition && part.Condition === "NEW" && (
                  <span className="inline-block bg-green-200 rounded-full px-3 py-1 text-sm lg:text-base font-semibold text-green-700 mr-2 mb-2">
                    Condition: {part.Condition}
                  </span>
                )}
                {part.Condition && part.Condition === "REFURBISHED" && (
                  <span className="inline-block bg-orange-200 rounded-full px-3 py-1 text-sm lg:text-base font-semibold text-orange-700 mr-2 mb-2">
                    Condition: {part.Condition}
                  </span>
                )}
                {part.Color && (
                  <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm lg:text-base font-semibold text-gray-700 mr-2 mb-2">
                    Color: {part.Color}
                  </span>
                )}
              </div>
            </div>
          );
        })}

      {data && price && !loading && (
        <div className="overflow-hidden shadow-lg text-center w-max p-10 lg:p-40 mb-4 h-screen bg-gray-100">
          <div className="text-left">
            <button
              className="bg-blue-500 rounded-full px-3 py-1 text-sm lg:text-base font-semibold text-gray-200 mr-2 mb-2"
              onClick={(e) => setPrice(null)}
            >
              Back
            </button>
          </div>

          <div>
            <p className="py-2 px-2 font-bold">
              Would you like to change the price?
            </p>
            <div className="px-6 pt-2 pb-2">
              <button
                className="inline-block bg-green-700 rounded-full px-3 py-1 text-sm lg:text-base font-semibold text-gray-200 mr-2 mb-2"
                onClick={(e) => setNewPrice(true)}
              >
                Yes
              </button>
              <button
                className="inline-block bg-red-700 rounded-full px-3 py-1 text-sm lg:text-base font-semibold text-gray-200 mr-2 mb-2"
                onClick={() => setOpen(true)}
              >
                No
              </button>
            </div>
            {newPrice && (
              <div className="mt-3">
                <label className="py-2 px-2 font-bold">
                  Enter the new price:
                </label>
                <br />
                <input
                  type="text"
                  className="rounded text-lg text-dark-500 py-2 px-2"
                  onBlur={(e) => setAmount(e.target.value)}
                />
                <button
                  className="inline-block bg-purple-400 rounded-full px-3 py-1 text-md lg:text-base font-bold text-gray-100 mr-2 mb-2"
                  onClick={(e) => setOpen(true)}
                >
                  Send
                </button>

                <p className={"text-red-600 font-bold"}>
                  Original price is {selectedPart.Price}
                </p>
              </div>
            )}
          </div>
        </div>
      )}

      {data && price && loading && (
        <div
          className="bg-blue-100 border-l-4 border-orange-500 text-orange-700 p-4"
          role="alert"
        >
          <p className="font-bold text-md lg:text-xl">
            <FontAwesomeIcon icon={faLock} /> Encrypting your message
          </p>
          <p className="text-md lg:text-xl">Please wait...</p>
        </div>
      )}
    </Row>
  );
};

export default Inventories;
