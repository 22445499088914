import { createSlice } from "@reduxjs/toolkit";
import JwtService from "../api/jwt.service";

let initialState = {
  name: null,
  url: null,
  email: null,
  token: null,
  isAdmin: false,
};

const loggedInUser = createSlice({
  name: "loggedInUser",
  initialState,
  reducers: {
    setLoggedInUser(state, action) {
      state.email = action.payload.user.email;
      state.name = action.payload.user.name;
      state.url = action.payload.user.url;
      state.isAdmin = action.payload.user.IsAdmin;

      if (action.payload.token) {
        JwtService.saveToken(action.payload.token);
      }
    },
    destroyLoggedInUser(state, action) {
      Object.keys(state).forEach(function (index) {
        state[index] = null;
      });
      JwtService.destroyToken();
    },
  },
});

export const { setLoggedInUser, destroyLoggedInUser } = loggedInUser.actions;

export default loggedInUser.reducer;
