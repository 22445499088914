import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Login from "../pages/Login";
import Inventories from "../pages/Dashboard";
import NotFound from "../pages/NotFound";
import Offer from "../pages/Offer";
import "../index.css";
import { useDispatch } from "react-redux";
import JwtService from "../api/jwt.service";
import AuthService from "../api/auth.service";
import { setLoggedInUser } from "../store/loggedInUser.slice";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    if (JwtService.getToken()) {
      AuthService.me().then((loggedInUser) => {
        dispatch(setLoggedInUser(loggedInUser));
      });
    }
  }, [dispatch]);

  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route path="/login" component={Login} />
        <Route path="/inventories" component={Inventories} />
        <Route exact path="/offer/:id" component={Offer} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
}

export default App;
