import React from "react";
import { Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAmazon } from "@fortawesome/free-brands-svg-icons";

const InventoryDetail = ({ part, price }) => {
  return (
    <Row>
      <div className="overflow-hidden shadow-lg text-center w-max p-10 lg:p-48 bg-gray-100">
        {part && (
          <div className="max-h-screen max-w-sm rounded mx-auto">
            <img
              className="max-w-sm mx-auto h-40 md:h-64 lg:h-96"
              src={part.URL}
              alt={part.URL}
            />
            <div className="px-6 py-4">
              <div className="mb-2">
                <span className="inline-block bg-blue-500 rounded-full px-3 py-1 font-bold text-white mr-2 mb-2 text-lg lg:text-3xl">
                  {part.PartNumber}
                </span>
              </div>
              <p className="text-gray-700 text-md lg:text-xl mt-2 mb-2">
                {part.Description}
              </p>
              {part.Model && (
                <p className="text-gray-700 text-md lg:text-xl mt-2 mb-2">
                  <b>Model:</b> {part.Model}
                </p>
              )}
              <p className="text-gray-700 text-md lg:text-xl mt-2 mb-2">
                <b>Brand:</b> {part.Brand}
              </p>
              <p className="text-gray-700 text-md lg:text-xl mt-2 mb-2">
                <b>Dimensions:</b> {part.Dimensions}
              </p>
              <p className="text-gray-700  text-md lg:text-xl mt-2 mb-2">
                <b>Weight:</b> {part.Weight}
              </p>
              <p className="text-gray-700  text-md lg:text-xl mt-2 mb-2">
                <b>Unit Price: </b>
                <span className="text-red-600 font-bold">
                  {price ? price : part.Price}
                </span>
              </p>
              <p className="text-gray-700  text-md lg:text-xl mt-2 mb-2">
                <b>Quantity Available: </b>
                <span className="text-green-600 font-bold">
                  {part.InventoryCount}
                </span>
              </p>

              <div className="mt-4">
                {part.ASIN && (
                  <a
                    href={`https://amazon.com/s?k=${part.ASIN}`}
                    target={"_blank"}
                    rel={"noreferrer"}
                    className="bg-yellow-300 hover:bg-yellow-400 text-dark font-bold py-2 px-3 lg:py-3 lg:px-6 rounded text-sm lg:text-xl no-underline"
                  >
                    Check Listing on &nbsp;
                    <FontAwesomeIcon icon={faAmazon} />
                  </a>
                )}
              </div>
            </div>
            <div className="px-6 py-4 pt-2 pb-2">
              {part.UPC && (
                <span className="inline-block bg-orange-200 rounded-full px-3 py-1 text-sm lg:text-base font-semibold text-orange-700 mr-2 mb-2">
                  UPC: {part.UPC}
                </span>
              )}
              {part.Condition && part.Condition === "NEW" && (
                <span className="inline-block bg-green-200 rounded-full px-3 py-1 text-sm lg:text-base font-semibold text-green-700 mr-2 mb-2">
                  Condition: {part.Condition}
                </span>
              )}
              {part.Condition && part.Condition === "REFURBISHED" && (
                <span className="inline-block bg-orange-200 rounded-full px-3 py-1 text-sm lg:text-base font-semibold text-orange-700 mr-2 mb-2">
                  Condition: {part.Condition}
                </span>
              )}
              {part.Color && (
                <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm lg:text-base font-semibold text-gray-700 mr-2 mb-2">
                  Color: {part.Color}
                </span>
              )}
            </div>
          </div>
        )}
      </div>
    </Row>
  );
};

export default InventoryDetail;
