import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Layout from "../components/Layout";
import InventoryDetail from "../components/InventoryDetail";
import PartService from "../api/part.service";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import Cryptr from "cryptr";

const Offer = () => {
  var { id } = useParams();
  const [part, setPart] = useState(null);
  const [price, setPrice] = useState(null);

  useEffect(() => {
    let part_id = id;

    if (id.includes("&")) {
      part_id = id.split("&")[0];
      let cryptr = new Cryptr("myTotalySecretKey");
      var new_price = id.split("&")[1].split("=")[1];
      setPrice("$" + parseFloat(cryptr.decrypt(new_price)));
    }

    PartService.get(part_id).then((parts) => {
      setPart(parts[0]);
    });
  }, [id]);

  return (
    <Layout>
      <Helmet>
        <title>{part ? part.Description : "PY Catalog"}</title>
        <meta
          name="description"
          content={part ? part.Description : "PY Catalog"}
        />
        <meta
          property="og:title"
          content={part ? part.Description : "PY Catalog"}
        />
        <meta property="og:image" content={part ? part.URL : "PY Catalog"} />
      </Helmet>
      {part && <InventoryDetail part={part} price={price} />}
      {!part && (
        <div className="text-center">
          <h2 className="mt-4">Sorry, no offer found. Please try again.</h2>
          <br />
          <img
            src={window.location.origin + "/404.png"}
            alt="not_found"
            className="mx-auto"
          />
        </div>
      )}
      <Footer />
    </Layout>
  );
};

export default Offer;
