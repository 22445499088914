import Cookies from "js-cookie";
const ACCESS_TOKEN_KEY = "D%xXs4_TE/xg5Yvgg>-Ebzt%:ECL}'=7)b+3M4;r^{f~)8X%Y";
const ACCESS_TOKEN_EXPIRES_IN = 30 * 60 * 60 * 1000;

const JwtService = {
  getToken: () => {
    return Cookies.get(ACCESS_TOKEN_KEY);
  },
  saveToken: (token) => {
    Cookies.set(ACCESS_TOKEN_KEY, token, {
      expires: ACCESS_TOKEN_EXPIRES_IN,
    });
  },
  destroyToken: () => {
    Cookies.remove(ACCESS_TOKEN_KEY);
  },
};

export default JwtService;
